import React from "react";
import "./datascience.css";

const Datascience = () => {
  return (
    <div class="dsmain">
      <div class="DataScience-intro">
        <h3>Data Science</h3>
        <p>Star Classification</p>
      </div>
      <div class="projectboxesds">
        <div class="offsetds">
          <a class="box1 box" href="">
            Star Type Classification Data Analysis
          </a>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
        </div>
        <div class="offset2"></div>
      </div>
    </div>
  );
};

export default Datascience;
