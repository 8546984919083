import React from "react";
import "./home.css";

document.body.style.backgroundColor = "white";
const Home = () => {
  return (
    <div class="main">
      <div class="introduction_name">
        <p>John Robinson</p>
      </div>
      <div class="introduction_desc">
        <p>Web & Software Developer</p>
      </div>
      <div class="introduction_text">
        <br></br>
        <p>
          I develop Full Stack Web Applications and Software Applications. I
          have a background in Computer Science with a focus on Machine Learning
          and Game Development. In addition I have experience with Data Science
          and Database development. My tech stack is viewable in the skills
          section and projects from all the aformentioned categories are
          viewable in the projects section. Please contact me if you are
          interested in working with me, just wanting to talk, or any other
          reason.{" "}
        </p>
      </div>
      <br></br>
      <div class="projects">
        <p>Current Projects:</p>
      </div>
      <div class="projects_list">
        <br></br>
        <ul class="projectUL">
          <li>Calender Webpage (React)</li>
          <li>Unity Tile Mesh Editor/RTS Game (C#)</li>
          <li>
            Machine Learning Automated Calender Appointment Detection
            Service(Python, Selenium)
          </li>
          <li>Machine Learning Email Appointment Detection Service(Python)</li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
