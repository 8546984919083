import React from "react";
import "./database.css";

const Database = () => {
  return (
    <div class="dbmain">
      <div class="db-intro">
        <h3>Database projectsd</h3>
        <p>Album and library managment databases</p>
      </div>
      <div class="projectboxesdb">
        <div class="offsetdb">
          <a class="box1 box" href="">
            Album Management Database
          </a>
          <a class="box2 box" href="">
            Library Management Database
          </a>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
        </div>
        <div class="offset2"></div>
      </div>
    </div>
  );
};

export default Database;
