import React from "react";
import "./threedmodel.css";

const Threedmodel = () => {
  return (
    <div class="threedmain">
      <div class="threed-intro">
        <h3>3D modeling</h3>
        <p>examples</p>
      </div>
      <div class="projectboxestd">
        <div class="offsettd">
          <a class="box1 box" href="">
            3d Model
          </a>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
          <div class="box_c box" href="">
            <img class="under-construction" src="under-construction.jpg"></img>
          </div>
        </div>
        <div class="offset2"></div>
      </div>
    </div>
  );
};

export default Threedmodel;
